import React from 'react';
import './ModalCookie.css';

interface ModalCookieProps {
  visible: boolean;
  handleAccept: () => void;
  onClose: () => void;
}

const ModalCookie: React.FC<ModalCookieProps> = ({
  visible,
  handleAccept,
  onClose,
}) => {
  if (!visible) return null;

  return (
    { visible } && (
      <div className="modal-overlay">
        <div className="modal-content">
          <span className="close" onClick={onClose}>
            &times;
          </span>
          <h2 className="modal-title">NOUS UTILISONS DES COOKIES</h2>
          <span className="line"></span>
          <p>
            En poursuivant vous acceptez l'utilisation des cookies par <span style={{color: "rgb(245, 53, 60)"}}>Colisport </span>
            afin de vous proposer des contenus adaptés et réaliser des
            statistiques. En acceptant vous contribuez à l'amélioration continue
            de Colisport.
          </p>
          <div className="modal-actions">
            <button className="button accept-btn" onClick={handleAccept}>
              J'accepte
            </button>
            <button className="decline-btn" onClick={onClose}>
              Refuser et fermer
            </button>
          </div>
        </div>
      </div>
    )
  );
};

export default ModalCookie;
