import { useState } from 'react';
import './App.css';
import Accordion from './components/Accordion';
import data from './data.json';
import ReactGA from 'react-ga4';
import ModalCookie from './components/ModalCookie';

function App() {
  const [isFullScreen, setIsFullScreen] = useState<boolean>(false);
  const [closeModal, setCloseModal] = useState(false);

  const handleClick = () => {
    window.location.href = 'https://colisport.fr/oneway/';
  };

  const handleFeedback = () => {
    window.location.href = 'mailto:Oneway@colisport.fr';
  };

  const handleImageClick = () => {
    setIsFullScreen(true);
  };

  const handleCloseFullScreen = () => {
    setIsFullScreen(false);
  };
  
  const initializateGoogleAnalytics = () => {
    setCloseModal(true)
    ReactGA.initialize('G-QVRNLZWYZB');
    ReactGA.send({ hitType: 'pageview', page: '/', title: "ONEWAY" });
  }

  return (
    <div>
      <header className="header_container">
        <img
          src="Logo colisport blanc.svg"
          alt="colisport logo"
          className="header_logo left"
        />
        <img
          src="barre blanche.svg"
          alt="region pays de la loire logo"
          className="header_logo right"
        />
        <div className="header_text">
          <h1 className="header_title">VOYAGEURS À VÉLO,</h1>
          <h1 className="header_title">
            TESTEZ LE NOUVEAU SERVICE DE TRANSPORT&nbsp;VÉLO
          </h1>
          <h1 className="header_subtitle">EN PAYS DE LA LOIRE</h1>
          <h3 className="header_subtitle">PÉDALEZ, DÉPOSEZ ET EXPÉDIEZ</h3>
          <h3 className="header_subtitle">VOTRE VÉLO LÀ OÙ VOUS LE VOULEZ</h3>
          <button onClick={handleClick} className="button button_reserver">
            RÉSERVER MAINTENANT
          </button>
        </div>
      </header>

      <main style={{ marginBottom: '3rem' }}>
        <section>
          <div className="flex_container">
            <div>
              <h2 className="title">Comment ça marche ?</h2>
              <p className="special-text">
                Durant tout l’été, les voyageurs à vélo peuvent expérimenter un
                nouveau service proposé le long des grands itinéraires qui
                sillonnent les Pays de la Loire. Profitez de l’expérience et
                plannifiez simplement votre voyage en 4 étapes :
              </p>
              <img
                src={require('./images/Schema-parcours.jpg')}
                alt="Schema Parcours"
                className="steps"
              />
            </div>
            <div className="map_container">
              <img
                src={require('./images/TOURSIME-VELO_Carte-NEW(1).jpg')}
                alt="Carte Parcours"
                className="map"
                onClick={handleImageClick}
              />
              <p className="special-text mapcaption">
                Itinéraires à vélo et points de dépôt
              </p>
            </div>
          </div>
          {isFullScreen && (
            <div
              className="full-screen-overlay"
              onClick={handleCloseFullScreen}
            >
              <img
                src={require('./images/TOURSIME-VELO_Carte-NEW(1).jpg')}
                alt="Carte Parcours"
                className="full-screen-image"
              />
            </div>
          )}
        </section>
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <button onClick={handleClick} className="button button_reserver">
            RÉSERVER MAINTENANT
          </button>
        </div>
        <section>
          <div className="flex_container">
            <img
              src={require('./images/sem_0018920_MD.jpg')}
              alt="cycling in nature"
              className="image_group"
            />
            <img
              src={require('./images/sem_0023066_MD.jpg')}
              alt="cycling in nature"
              className="image_group"
            />
            <img
              src={require('./images/sem_0018866_MD.jpg')}
              alt="cycling in nature"
              className="image_group"
            />
            <img
              src={require('./images/sem_0018754_MD.jpg')}
              alt="cycling in nature"
              className="image_group"
            />
          </div>
        </section>
        <section>
          <div>
            <h2 className="title">Votre avis nous intéresse</h2>
            <p className="special-text">
              N’hésitez pas à nous laisser votre avis sur ce service
              expérimental. Vos retours d’expériences sont précieux. Ils nous
              serviront à optimiser l’expérience et à sa reconduction
              éventuelle.
            </p>
            <button onClick={handleFeedback} className="button">
              Envoyer vos suggestions
            </button>
          </div>
        </section>
        <section>
          <div className="flex_container">
            <img
              src={require('./images/logos/logo_2022_s&co_cmjn_fond_blanc.png')}
              alt="sponsor logo"
              className="logo_group"
            />
            <img
              src={require('./images/logos/Logo-2024-centré1.png')}
              alt="sponsor logo"
              className="logo_group"
            />
            <img
              src="Logo colisport.svg"
              alt="sponsor logo"
              className="logo_group"
            />
            <img
              src={require('./images/logos/LOGO_RVB_digital.png')}
              alt="sponsor logo"
              className="logo_group"
            />
          </div>
        </section>
        <section>
          <div className="flex-container">
            <h2 className="title">Questions Fréquemment Posées</h2>
            <Accordion items={data} />
          </div>
        </section>
        <section>
          <div>
            <p
              className="special-text"
              style={{
                fontSize: 'clamp(0.5rem, 1vw + 0.1rem, 0.7rem)',
                fontWeight: 'bold',
              }}
            >
              Crédit photo : Bannière - © La Vélodyssée, Aurélie Stapf -
              porteurdesonge.com ; 1, 3, 4 - © A. Lamoureux ; 2 - La
              Vélobuissonnière © Pascal Beltrami
            </p>
          </div>
        </section>
        <ModalCookie visible={ !closeModal } handleAccept={initializateGoogleAnalytics} onClose={() => setCloseModal(true)}/>
      </main>
    </div>
  );
}

export default App;
